html,
body,
#react-root {
  height: 100%;
}

body {
  font-family: 'Albert Sans', Tahoma, Geneva, Verdana, sans-serif;
  margin: 0px;
  background-color: white;
}


/* -----------------------------------------------------------------
Header
----------------------------------------------------------------- */

.headerOuter {
  display: grid;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-template-rows: 2;
  top: 0;
  height: 133px;
  position: fixed;
  width: 100%;
  z-index: 5000;
  background-color: white;
}

.headerName {
  grid-row-start: 1;
  justify-content: center;
  text-align: center;
}

h1 {
  font-family: "Kolker Brush";
}

.subheader {
  grid-row-start: 2;
  justify-content: center;
  text-align: center;
  height: 20px;
}


/* -----------------------------------------------------------------
Nav Bar
----------------------------------------------------------------- */

.navBarContent {
  height: 40px;
  margin-top: 133px;
  margin-bottom: 0px;
  width: 100%;
  display: grid;
  grid-template-columns: 3;
  background-color: white;
  border-bottom: black 2px solid;
  position: fixed;
  width: 100%;
  z-index: 4000;
}

.navLinksLeft {
  grid-column-start: 1;
  display: flex;
  justify-content: flex-start;
  justify-self: flex-start;
  width: 115px;
  margin: 0px 0px 0px 10px;
}

.navLinksRight {
  grid-column-start: 3;
  display: flex;
  justify-content: flex-end;
  justify-self: flex-end;
  width: 115px;
  margin: 0px 10px 0px 0px;
}


/* -----------------------------------------------------------------
General / Misc
----------------------------------------------------------------- */

.container {
  /* margin-top: 173px; */
  padding: 193px 20px 5px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: 100%; */
  background-color: white;
}

.horizontalLine {
  border-bottom: black 2px solid;
}

.containerAdditional {
  padding: 40px 20px 40px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin-bottom: 15px;
}

.lineBreak {
  flex-basis: 100%;
  height: 0;
}


/* -----------------------------------------------------------------
Bulletin — Optional News / Alert at Top of /home
----------------------------------------------------------------- */

.bulletinBox {
  border: 3px solid black;
  padding: 5px 10px 5px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  min-width: 270px;
  background-color: #F8F8F8;
}


/* -----------------------------------------------------------------
Home 
----------------------------------------------------------------- */

.homeContainer {
  padding-top: 173px;
}

.coverImage {
  width: 100%;
}


/* -----------------------------------------------------------------
Paintings
----------------------------------------------------------------- */

.latestHeader {
  height: 30px;
  margin-top: 25px;
  padding-bottom: 20px;
}

.latestImageLandscape {
  margin: 10px 20px 0px 20px;
  max-width: 100%;
}

.captionUnder {
  margin: 4px 0px 0px 0px;
}

.latestCloseupsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 10px 0px 40px 0px;
}

.zoomImage {
  border: 4px solid black;
  max-width: 200px;
  margin: 25px 5px 5px 5px;
}

.portraitureHeader {
  height: 65px;
  margin-top: 25px;
  padding-bottom: 15px;
}

.paintingsOuterContainer{
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 10px 0px 10px;
}

.paintingsSectionContainer{
  background-color: black;
  padding: 5px 20px 70px 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.canvasContainer {
  padding: 10px;
}

.canvasPortrait {
  width: 100%;
  max-width: 300px;
}

.canvasLandscape {
  width: 100%;
  max-width: 400px;
}


/* -----------------------------------------------------------------
Photographs
----------------------------------------------------------------- */

.photosetContainer {
  background-color: white;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.photograph {
  margin: 10px;
  width: 100%;
  max-width: 500px;
}

/* -----------------------------------------------------------------
About
----------------------------------------------------------------- */

.aboutPhoto {
  border: 5px solid black;
  max-width: 250px;
  margin-top: 30px;
}

.intro {
  max-width: 500px;
  margin-top: 20px;
  justify-items: center;
}